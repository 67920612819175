import React from 'react';
import {
  Box,
  Card,
  Typography,
  Divider,
  CircularProgress,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

import moment from 'moment';

import { RelatedQuestionnaireSubmissionQuestion } from '~/src/entities/questionnairesV2/types';

type UnmappedQuestionsWidgetProps = {
  data: RelatedQuestionnaireSubmissionQuestion[] | undefined;
  isLoading: boolean;
  onClose: () => void;
};

const UnmappedQuestionsWidget = ({
  data,
  isLoading,
  onClose,
}: UnmappedQuestionsWidgetProps) => {
  const renderSingleItem = (d: RelatedQuestionnaireSubmissionQuestion) => {
    return (
      <Box
        key={d.id}
        sx={(theme) => ({
          margin: '15px',
          padding: '5px 10px',
          border: '1px solid',
          borderRadius: '5px',
          borderColor: theme.palette.grey[300],
        })}
      >
        <Box sx={{ marginBottom: '5px' }}>
          <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
            {d.question}
          </Typography>
        </Box>
        <Box sx={{ marginBottom: '5px' }}>
          <Typography sx={{ fontSize: '12px' }}>{d.response}</Typography>
        </Box>
        <Box sx={{ marginBottom: '5px', display: 'flex' }}>
          <Box>
            <Typography sx={{ fontSize: '10px', color: 'gray' }}>
              created at: {moment(d.created_at).format('MM/DD/YYYY')}
            </Typography>
          </Box>

          <Box sx={{ margin: '0 5px', fontSize: '10px' }}>|</Box>

          <Box>
            <a
              href={`/clients/questionnaires/${d.questionnaire_id}/responses/${d.questionnaire_submission_id}`}
              target="_blank"
              rel="noreferrer"
            >
              <Typography sx={{ fontSize: '10px', color: 'blue' }}>
                View questionnaire
              </Typography>
            </a>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderList = () => (
    <Box
      sx={{
        overflowY: 'scroll',
        height: '550px',
      }}
    >
      {data!.map((d: RelatedQuestionnaireSubmissionQuestion) =>
        renderSingleItem(d),
      )}
    </Box>
  );

  return (
    <Card
      raised
      sx={{
        position: 'fixed',
        right: '32px',
        top: '137px',
      }}
    >
      <Box
        sx={{
          padding: '15px',
          display: 'flex',
        }}
      >
        <Box sx={{ marginRight: 'auto' }}>
          <Typography variant="subheading">Questionnaire Responses</Typography>
        </Box>
        <Box>
          <IconButton
            aria-label="close"
            onClick={() => {
              onClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
      </Box>

      <Box>
        <Divider />
      </Box>

      {isLoading && <CircularProgress />}

      {data && renderList()}
    </Card>
  );
};

export default UnmappedQuestionsWidget;
