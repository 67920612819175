import * as Sentry from '@sentry/react';

export const DEFAULT_ELEMENT_HEIGHT = 14;
export const DEFAULT_MULTILINE_ELEMENT_HEIGHT = 18;
export const scaleFactor = 1.333;

function wrapText(context, text, x, y, line_width, line_height) {
  let line = '';
  const paragraphs = (text || '').split('\n');
  for (let i = 0; i < paragraphs.length; i++) {
    if (i == 0) {
      y += 3;
    }
    const words = paragraphs[i].split(' ');
    for (let n = 0; n < words.length; n++) {
      const testLine = `${line + words[n]} `;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;
      if (testWidth > line_width && n > 0) {
        context.fillText(line, x, y);
        line = `${words[n]} `;
        y += line_height;
      } else {
        line = testLine;
      }
    }
    context.fillText(line, x, y);
    y += line_height;
    line = '';
  }
}

export const getFileFromUrl = async (url, name) => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type:
      response.headers.get('content-type') ||
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  });
};

export const adjustAnnotationBackgroundColorOnDownload = (widgetAnnot) => {
  if (
    widgetAnnot.Subject === 'Free Text' &&
    widgetAnnot.ToolName === 'AnnotationCreateFreeText'
  ) {
    /*
    widgetAnnot.FillColor: background color of AnnotationCreateFreeText(custom field),
    which should be RGBA color(0, 0, 0, 0):
    {
      R: 0,  // red
      G: 0,  // green
      B: 0,  // blue
      A: 0,  // alpha channel - which specifies the opacity for a color
    }
    */

    // if background color is not fully transparent (if alpha channel is not 0)
    if (widgetAnnot.FillColor.A !== 0) {
      const msg = `a mysterious FreeTextAnnotation with background color rgba(${widgetAnnot.FillColor.R}, ${widgetAnnot.FillColor.G}, ${widgetAnnot.FillColor.B}, ${widgetAnnot.FillColor.A}) dropped on drafting page.
clio-draft-frontend will force it to be fully transparent. i.e. will force it to be rgba(${widgetAnnot.FillColor.R}, ${widgetAnnot.FillColor.G}, ${widgetAnnot.FillColor.B}, 0)`;

      Sentry.captureMessage(msg);

      // force background color to be fully transparent (force alpha channel to be 0)
      widgetAnnot.FillColor.A = 0;
      /* 
      On production, occasionally, customers would get a mysterious FreeTextAnnotation 
      with white solid background(FillColor) on drafting page for unknown reason 
      (maybe because of race condition). 
      The mysterious FreeTextAnnotation will block whatever is beneath it upon download.
      The default value of background color(FillColor) of FreeTextAnnotation is transparent.
      Therefore, we use adjustAnnotationBackgroundColorOnDownload() to restore it from white solid to transparent(default value).
      Then, it will not block whatever is beneath it upon download.
      */
    }
  }
};

export const getPdfBlob = async function (
  webviewerInstance,
  downloadOnly = false,
) {
  const instanceViewer = webviewerInstance.docViewer;
  const doc = instanceViewer.getDocument();
  const annotManager = instanceViewer.getAnnotationManager();
  const annots = annotManager.getAnnotationsList();
  const mustDeleteMultilineInputs = [];
  const printOnlyMultilineInputs = [];
  // When downloading, create a custom multiline annotation that draws a canvas
  // and manually implements line height for given text. THe breaking up of text
  // as per its length has to be done manually as well to fit within the width.
  if (downloadOnly) {
    annots.forEach(async (widgetAnnot) => {
      if (
        widgetAnnot.Subject == 'Widget' &&
        widgetAnnot.getField().flags.get('Multiline') &&
        widgetAnnot.getCustomData('lineHeight')
      ) {
        const { Annotations } = webviewerInstance;
        const currentFieldName = widgetAnnot.getField().name;
        const multilineWidgetExists = annots.filter(
          (a) => a.getCustomData('originalFieldName') === currentFieldName,
        );
        if (multilineWidgetExists.length > 0) {
          multilineWidgetExists[0].Height = JSON.parse(
            multilineWidgetExists[0].getCustomData('widgetAnnotOriginalHeight'),
          );
          multilineWidgetExists[0].textToSet = widgetAnnot.getField().value;
          annotManager.redrawAnnotation(multilineWidgetExists[0]);
          printOnlyMultilineInputs.push(multilineWidgetExists[0]);
          mustDeleteMultilineInputs.push(widgetAnnot);
          widgetAnnot.Height = 0;
        } else {
          class MultilineAnnotation extends Annotations.CustomAnnotation {
            constructor() {
              super('multilineannot'); // provide the custom XFDF element name
              this.Subject = 'CustomMultiLineCanvas';
            }

            draw(ctx) {
              const x = this.X;
              const y = this.Y;
              const maxWidth = this.Width;

              const text = this.textToSet;
              const { lineHeight } = this;
              ctx.font = `${DEFAULT_ELEMENT_HEIGHT / scaleFactor}px Lato`;
              ctx.textBaseline = 'top';
              wrapText(ctx, text, x, y, maxWidth + 1, lineHeight);
            }
          }

          MultilineAnnotation.prototype.elementName = 'multilineannot';
          annotManager.registerAnnotationType(
            MultilineAnnotation.prototype.elementName,
            MultilineAnnotation,
          );
          const customMultilinePrintOnlyAnnot = new MultilineAnnotation();
          customMultilinePrintOnlyAnnot.PageNumber =
            widgetAnnot.getPageNumber();
          customMultilinePrintOnlyAnnot.X = widgetAnnot.getX();
          customMultilinePrintOnlyAnnot.Y = widgetAnnot.getY();
          customMultilinePrintOnlyAnnot.setCustomData(
            'originalFieldName',
            currentFieldName,
          );
          customMultilinePrintOnlyAnnot.setCustomData(
            'widgetAnnotOriginalHeight',
            widgetAnnot.getHeight(),
          );
          customMultilinePrintOnlyAnnot.textToSet =
            widgetAnnot.getField().value;
          customMultilinePrintOnlyAnnot.lineHeight =
            JSON.parse(widgetAnnot.getCustomData('lineHeight')) /
            (scaleFactor * 1.32);

          customMultilinePrintOnlyAnnot.Width = widgetAnnot.getWidth();
          customMultilinePrintOnlyAnnot.Height = widgetAnnot.getHeight() * 1.1;

          /*
          Check whether a custom annotation has a height as 0.
          If yes, do not add it to annotation manager.
          Otherwise, a custom annotation whose height is 0 will appear as 
          a red Draft stamp on a downloaded file.
          In this case, we use the original multiline widget instead.
          https://github.com/mystacksco/issues/issues/1407
          */
          if (customMultilinePrintOnlyAnnot.Height > 0) {
            annotManager.addAnnotation(customMultilinePrintOnlyAnnot);
            printOnlyMultilineInputs.push(customMultilinePrintOnlyAnnot);
            mustDeleteMultilineInputs.push(widgetAnnot);
            widgetAnnot.Height = 0;
          }
        }
      }

      adjustAnnotationBackgroundColorOnDownload(widgetAnnot);
    });
  }

  const xfdfString = await annotManager.exportAnnotations();
  /* 
  We've exported annotations to an XFDF string.
  So, we restore original multiline widgets right away because we want
  to minimize the time that original multiline widgets have a height of 0px.
  */

  // When downloading draw and redraw the appropriate custom annotations
  // as the multiline annotaitons that are shown on downloaded pdf are different
  // from the one rendered in pdfviewer.
  if (downloadOnly) {
    printOnlyMultilineInputs.forEach((annot) => {
      annot.textToSet = '';
      annotManager.redrawAnnotation(annot);
    });
    mustDeleteMultilineInputs.forEach((annot) => {
      // restore original annotation
      annot.Height = JSON.parse(
        annot.getCustomData('widgetAnnotOriginalHeight'),
      );
    });
  }

  const options = { xfdfString, flatten: true };
  const data = await doc.getFileData(options);
  const arr = new Uint8Array(data);
  const blob = new Blob([arr], { type: 'application/pdf' });

  return {
    blobData: blob,
    numberOfPages: doc.getPageCount(),
    documentId: +doc.getDocumentId(),
  };
};
