import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';

import theme from '~/src/theme';
import Card from '../Card';
import Checkbox from '../Checkbox';
import type { ProductPricing } from '~/src/entities/subscription/hooks/useGetProductPricing';
import {
  getQualifyingQuestionnairePlan,
  modifyPlanPickerModalEntries,
} from '~/src/entities/subscription';
import { useClioQuestionnairesGaEnabled } from '~/src/entities/user';

export type Plan = {
  interval: string;
  name: string;
  price: number;
  id: string;
};

type ConfigurePlanModalProps = {
  handleCancel: () => void;
  handleChange: ({
    index,
    selected,
  }: {
    index: number;
    selected: boolean[];
  }) => void;
  handleUpdate: (selected: boolean[]) => boolean[];
  open: boolean;
  index: number;
  defaultValue: boolean[];
  plans: Plan[] | ((selected: boolean[]) => Plan[]);
  productPricingData: ProductPricing;
  selectedProductCategories: {
    courtForms: boolean;
    documentAutomation: boolean;
  };
  isEligibleForQuestionnairesEmbedded: boolean;
};

const ConfigurePlanModal = ({
  open,
  plans,
  index,
  defaultValue,
  handleCancel,
  handleChange,
  handleUpdate,
  productPricingData,
  selectedProductCategories,
  isEligibleForQuestionnairesEmbedded,
}: ConfigurePlanModalProps) => {
  const [selected, setSelected] = useState(defaultValue);
  const [latestSelectedOptions, setLatestSelectedOptions] =
    useState(defaultValue);
  const isQuestionnairesGaEnabled = useClioQuestionnairesGaEnabled() === true;

  const plansToRender = typeof plans === 'function' ? plans(selected) : plans;
  plansToRender.forEach((plan) => {
    const productPrice = productPricingData[plan.id];
    plan.price = (productPrice || 0) / 100;
  });

  // we don't want to refactor this rn since it's used in multiple places.
  // index = 0 represents cf because its first in the UI (0 indexed)
  const modalType = index ? 'da' : 'cf';
  const showQuestionnairePricingLabels =
    isQuestionnairesGaEnabled && !isEligibleForQuestionnairesEmbedded;

  const formCycleLabel = plansToRender[0]?.interval === 'mo' ? 'month' : 'year';
  const firstFormQuestionnairesProduct = getQualifyingQuestionnairePlan({
    isCourtFormsSelected: selectedProductCategories.courtForms,
    isDocAutoSelected: selectedProductCategories.documentAutomation,
    billingFrequency: plansToRender[0]?.interval ?? '',
  });
  const firstFormPrice =
    (productPricingData[firstFormQuestionnairesProduct] ?? 0) / 100 +
    (plansToRender[0]?.price ?? 0);
  const additionalFormPrice = plansToRender[0]?.price;
  const cfPriceHint = `First library is $${firstFormPrice}/${formCycleLabel}. Additional libraries are $${additionalFormPrice}/${formCycleLabel}.`;

  modifyPlanPickerModalEntries({
    plansToRender,
    latestSelectedOptions,
    isCourtFormsSelected: selectedProductCategories.courtForms,
    isDocAutoSelected: selectedProductCategories.documentAutomation,
    isEligibleForQuestionnairesEmbedded,
    modalType,
    productPricingData,
    isQuestionnairesGaEnabled,
  });

  const title = index
    ? 'Add document automation to your plan'
    : 'Choose libraries';

  const onConfirm = () => {
    handleChange({ index, selected });
  };

  const toggleCheck = (index: number) => {
    const nextSelected = selected.map((s, i) => (index === i ? !s : s));
    setLatestSelectedOptions(nextSelected);
    setSelected(handleUpdate ? handleUpdate(nextSelected) : nextSelected);
  };

  return (
    <Dialog
      open={open}
      onClose={handleCancel}
      scroll={'paper'}
      PaperProps={{
        sx: {
          width: '100%',
          maxHeight: '560px!important',
          minWidth: '560px!important',
        },
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent dividers={true}>
        {modalType === 'cf' && showQuestionnairePricingLabels && (
          <div className="text-sm mb-4">{cfPriceHint}</div>
        )}
        {plansToRender &&
          plansToRender.map(
            ({ interval, name, price, ...checkboxProps }, key) => (
              <div className="w-full mb-2" key={key}>
                <Card dynamic>
                  <div className="flex flex-row items-start">
                    <Checkbox
                      {...checkboxProps}
                      id={key}
                      disabled={false}
                      checked={selected[key]}
                      color={theme.colors.darkBlue}
                      size={3}
                      onClick={() => toggleCheck(key)}
                    />
                    <div className="flex flex-row items-center justify-between w-full ml-2">
                      <div className="flex flex-col">
                        <div className="font-medium text-sm mb-1">{name}</div>
                      </div>
                      <div className="font-normal text-xs">
                        {`$${price}/ ${interval}`}
                      </div>
                    </div>
                  </div>
                </Card>
              </div>
            ),
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={() => onConfirm()} variant="contained">
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfigurePlanModal;
