import * as React from 'react';

export const UIFlags = {
  cardLists: false,
  templateDraftingList: false,
  progressTracker: false,
  newSubscribePage: false,
  newDocumentSelectionPanel: false,
  deprecatedQuestionnairesTable: true,
  questionnaireReuseUntaggedFieldMappings: false,
  questionnaireSelectDocumentsBanner: false,
  cbsIntegration: false,
  autoGeneratePredefinedQuestionnaireQuestions: false,
};

export type UIFlag = keyof typeof UIFlags;

export const UI_FLAG_LABELS: Record<UIFlag, string> = {
  cardLists: 'Card Lists',
  templateDraftingList: 'Remove Template Drafting Select',
  progressTracker: 'Progress Tracker Widget',
  newSubscribePage: 'Upated Subscribe Page',
  newDocumentSelectionPanel: 'New Document Selection Panel',
  deprecatedQuestionnairesTable: 'Deprecated Questionnaires Table',
  questionnaireReuseUntaggedFieldMappings:
    'Questionnaire: re-use untagged field mappings',
  questionnaireSelectDocumentsBanner: 'Questionnaire Select Documents Banner',
  cbsIntegration: 'CBS Integration',
  autoGeneratePredefinedQuestionnaireQuestions:
    'Auto-generate predefined questionnaire questions',
};

export type FlagSetter = (flag: UIFlag, enabled: boolean) => void;

type UIFlagsContextType = {
  flags: typeof UIFlags;
  setFlag: FlagSetter;
  enabled: boolean;
};

export const UIFlagsContext = React.createContext<
  UIFlagsContextType | undefined
>(undefined);

export const useUIFlagsContext = () =>
  React.useContext(UIFlagsContext) as UIFlagsContextType;

export const useUIFlags = () => useUIFlagsContext().flags;

export const useAreUIFlagsEnabled = () => useUIFlagsContext().enabled;

export const useToggleUIFlag = () => {
  const { setFlag, flags } = useUIFlagsContext();
  return (flag: UIFlag) => setFlag(flag, !flags[flag]);
};

export const useUIFlagEnabled = (flag: UIFlag) => useUIFlags()[flag];

export const useCheckUIFlagEnabled = () => {
  const flags = useUIFlags();
  return (flag: UIFlag) => flags[flag];
};
