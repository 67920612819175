import {
  ALL_COURT_FORMS_ACCESSES,
  FeatureAccessType,
} from '../featureAccesses';
import { useCurrentOrg, getOrg } from './useCurrentOrg';
import { stateCodeToStateName } from '~/src/utils/stateCodeToStateName';
import { queryClient } from '~/src/utils/queryClient';
import { Organization } from './useMemberships';

export type StateCode = keyof typeof stateCodeToStateName;

export const canOrgUseFeatureAccess = (
  access: FeatureAccessType,
  org: Organization | undefined,
) => org?.feature_accesses[access]?.can_use_feature;

export const useFeatureAccess = (access: FeatureAccessType) =>
  canOrgUseFeatureAccess(access, useCurrentOrg().org);

export const useCheckFeatureAccess = () => {
  const org = useCurrentOrg().org!;
  return (access: FeatureAccessType) => canOrgUseFeatureAccess(access, org);
};

export const useCbsIntegrationEnabled = () =>
  useFeatureAccess('CBS_INTEGRATION');

export const useQuestionnairesEnabled = () => useFeatureAccess('INTAKE_FORMS');

export const useClioQuestionnairesEnabled = () =>
  useFeatureAccess('QUESTIONNAIRES');

export const useClioQuestionnairesGaEnabled = () =>
  useFeatureAccess('QUESTIONNAIRES_GA');

export const useWordDocumentAutomationEnabled = () =>
  useFeatureAccess('WORD_DOCUMENT_AUTOMATION');

export const useCourtFormsEnabled = () =>
  ALL_COURT_FORMS_ACCESSES.some(useCheckFeatureAccess());

export const useFederalCourtFormsEnabled = () =>
  useFeatureAccess('FEDERAL_FORMS');

export const useIsUnifiedPlatform = () => useFeatureAccess('TAGS_V2');

export const useSubscriptionV2Enabled = () =>
  useFeatureAccess('SUBSCRIPTIONS_V2');

export const useIsClioLead = () => useFeatureAccess('MANAGE_LEAD');

export const useIsClioFree = () => useFeatureAccess('CLIO_FREE');

export const useSingleMatterImportEnabled = () =>
  useFeatureAccess('SINGLE_MATTER_IMPORT');

export const useTemplateDefaultValuesEnabled = () =>
  useFeatureAccess('TEMPLATE_DEFAULT_VALUES');

export const useCheckStateFormsEnabled = () => {
  const check = useCheckFeatureAccess();
  return (stateCode: StateCode) =>
    check(`${stateCode}_JC_FORMS` as FeatureAccessType);
};

export const isFeatureAccessEnabled = (
  access: FeatureAccessType,
  client = queryClient,
) => canOrgUseFeatureAccess(access, getOrg(client));

export const useIsCanary = (): boolean =>
  useFeatureAccess('DRAFT_SIGNAL_SUPPORT') === true;

export const useIsMaintenanceBannerEnabled = (): boolean =>
  useFeatureAccess('MAINTENANCE_BANNER') === true;
