import React, { useEffect } from 'react';
import { LoadingOverlay } from '~/src/components/PageLayout';
import {
  Document,
  Question,
  QuestionType,
  Section,
  mergeFieldGroups,
  type Questionnaire,
} from '@clio/questionnaire-builder';
import {
  useGetClioQuestionnaireById,
  useGetDocumentFields,
  useGetQuestionnaireTemplates,
  useUpdateClioQuestionnaire,
  useGetPreviouslyMappedQuestions,
} from '~/src/entities/questionnaires';
import { useCurrentOrgFprint } from '~/src/entities/user';
import { useUIFlagEnabled } from '~/src/entities/uiFlags';
import { useSnackbar } from 'notistack';
import { history } from '~/src/utils/history';

type QuestionOption = {
  label: string;
  value?: string;
  question_option_type: 'QuestionOptionStatic' | 'QuestionOptionCustom';
  question_option_mappings_attributes: {
    external_field_identifier: string;
  }[];
};

type PartialSection = Partial<Section> & {
  questions_attributes: PartialQuestion[];
};

type PartialQuestion = Partial<Question> & {
  question_options_attributes?: Partial<QuestionOption>[];
  question_mappings_attributes: Partial<{
    external_field_identifier: string;
    questionnaire_id: number;
  }>[];
};

export type PreviouslyMappedQuestion = PartialQuestion & {
  section_title: string;
  section_description: string;
};

// create questions from untagged fields
export const createQuestionsFromUntaggedFields = (
  previouslyMappedQuestions: PreviouslyMappedQuestion[],
  questionnaire: Questionnaire,
): Questionnaire => {
  previouslyMappedQuestions.forEach(
    (prevQuestion: PreviouslyMappedQuestion) => {
      const sectionFound: PartialSection | undefined =
        questionnaire.sections_attributes.find(
          (s) => s.title === prevQuestion.section_title,
        );
      if (sectionFound) {
        let nextQuestion: Question | PartialQuestion | undefined =
          sectionFound.questions_attributes.find(
            (currentQuestion) => currentQuestion.title === prevQuestion.title,
          );

        if (nextQuestion) {
          // prevQuestion exists in the current questionnaire
          prevQuestion.question_mappings_attributes.forEach((prevMapping) => {
            // check whether mapping from previous question exists
            const isFound = nextQuestion!.question_mappings_attributes.find(
              (nextMapping) =>
                nextMapping.external_field_identifier ===
                prevMapping.external_field_identifier,
            );
            if (!isFound) {
              const nextMapping = {
                external_field_identifier:
                  prevMapping.external_field_identifier,
                questionnaire_id: questionnaire.id,
              };
              nextQuestion!.question_mappings_attributes.push(nextMapping);
            }
          });
        } else {
          // prevQuestion does not exist in the current questionnaire. create a new question.
          const nextQuestionMappingsAttributes =
            prevQuestion.question_mappings_attributes.map((mapping) => {
              return {
                external_field_identifier: mapping.external_field_identifier,
                questionnaire_id: questionnaire.id,
              };
            });

          nextQuestion = {
            title: prevQuestion.title ?? '',
            description: prevQuestion.description ?? '',
            question_type: prevQuestion.question_type as QuestionType,
            question_options_attributes: [],
            question_mappings_attributes: nextQuestionMappingsAttributes,
            is_required: prevQuestion.is_required,
          };
          sectionFound.questions_attributes.push(nextQuestion);
        }

        if (
          prevQuestion.question_type === 'QuestionMultipleChoiceSingle' ||
          prevQuestion.question_type === 'QuestionMultipleChoiceMulti' ||
          prevQuestion.question_type === 'QuestionCheckbox'
        ) {
          const nextQuestionOptionsAttributes =
            prevQuestion.question_options_attributes!.map(
              (prevQuestionOption) => {
                const nextQuestionOptionMappingsAttributes =
                  prevQuestionOption.question_option_mappings_attributes.map(
                    (prevMapping) => {
                      return {
                        external_field_identifier:
                          prevMapping.external_field_identifier,
                      };
                    },
                  );

                const nextQuestionOption: QuestionOption = {
                  label: prevQuestionOption.label,
                  value: prevQuestionOption.value,
                  question_option_type: 'QuestionOptionStatic',
                  question_option_mappings_attributes:
                    nextQuestionOptionMappingsAttributes,
                };

                return nextQuestionOption;
              },
            );

          nextQuestion.question_options_attributes =
            nextQuestionOptionsAttributes;
        }
      }
    },
  );
  return questionnaire;
};

export const QuestionnaireAutoGeneration = ({
  questionnaireId,
}: {
  questionnaireId: string;
}) => {
  const orgFprint = useCurrentOrgFprint();
  const snackbar = useSnackbar();
  const {
    useReuseUntaggedFieldMappings,
    autoGeneratePredefinedQuestionnaireQuestions,
  } = {
    useReuseUntaggedFieldMappings: useUIFlagEnabled(
      'questionnaireReuseUntaggedFieldMappings',
    ),
    autoGeneratePredefinedQuestionnaireQuestions: useUIFlagEnabled(
      'autoGeneratePredefinedQuestionnaireQuestions',
    ),
  };

  const [isSuccess, setIsSuccess] = React.useState(false);
  const [hasAutoGenerateErrors, setHasAutoGenerateErrors] =
    React.useState(false);
  const [isTemplatesReady, setIsTemplatesReady] = React.useState(false);
  const [isDocsReady, setIsDocsReady] = React.useState(false);
  const [isQuestionnaireReady, setIsQuestionnaireReady] = React.useState(false);

  const { data: questionnaireTemplates, isError: isTemplatesError } =
    useGetQuestionnaireTemplates(orgFprint, questionnaireId, {
      onSuccess: () => {
        setIsTemplatesReady(true);
      },
    });
  const {
    data: documents,
    isError: isDocumentMappingError,
    mutateAsync: getDocumentFields,
  } = useGetDocumentFields(orgFprint);

  const updateClioQuestionnaire = useUpdateClioQuestionnaire(orgFprint);
  const getPreviouslyMappedQuestions =
    useGetPreviouslyMappedQuestions(orgFprint);

  const { data: questionnaire, isError: isQuestionnaireError } =
    useGetClioQuestionnaireById(orgFprint, questionnaireId, {
      onSuccess: () => {
        setIsQuestionnaireReady(true);
      },
    });

  // re-use untagged field mappings from previous questionnaire
  const reuseUntaggedFieldMappingsFromPreviousQuestionnaire = async (
    questionnaire: Questionnaire,
  ) => {
    if (!useReuseUntaggedFieldMappings) {
      return;
    }

    const untaggedRegex = /^untagged_\d+$/;
    let external_field_identifier_array: string[] = [];
    documents!.forEach((singleDoc) => {
      if (singleDoc.pdf_field_elements) {
        const untaggedFieldElements = singleDoc.pdf_field_elements.filter(
          (pdf_field_element) => untaggedRegex.test(pdf_field_element.tag),
        );
        const untaggedFieldElementTags = untaggedFieldElements.map(
          (e) => e.tag,
        );
        external_field_identifier_array = [
          ...external_field_identifier_array,
          ...untaggedFieldElementTags,
        ];
      }
    });

    const payload = {
      external_field_identifiers: external_field_identifier_array,
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let apiResponse = await getPreviouslyMappedQuestions.mutateAsync(payload);

    let previouslyMappedQuestions: PreviouslyMappedQuestion[] = apiResponse;

    // create sections from untagged fields
    previouslyMappedQuestions.forEach(
      (prevQuestion: PreviouslyMappedQuestion) => {
        const sectionFound = questionnaire.sections_attributes.find(
          (s) => s.title === prevQuestion.section_title,
        );
        if (!sectionFound) {
          const section: Section = {
            title: prevQuestion.section_title,
            description: prevQuestion.section_description,
            questions_attributes: [],
            display_order: questionnaire.sections_attributes.length + 1,
            show_logic: null,
            logic_enabled: false,
          };
          questionnaire.sections_attributes.push(section);
        }
      },
    );

    questionnaire = await updateClioQuestionnaire.mutateAsync({
      questionnaireId,
      clioQuestionnaire: {
        // @ts-ignore: we can partially update the questionnaire.
        ...questionnaire,
        analytics: [
          {
            name: 'Questionnaire - create section from untagged field',
            data: {},
          },
        ],
      },
    });

    createQuestionsFromUntaggedFields(previouslyMappedQuestions, questionnaire);

    questionnaire = await updateClioQuestionnaire.mutateAsync({
      questionnaireId,
      clioQuestionnaire: {
        // @ts-ignore: we can partially update the questionnaire.
        ...questionnaire,
        analytics: [
          {
            name: 'Questionnaire - create question from untagged field',
            data: {},
          },
        ],
      },
    });
  };

  useEffect(() => {
    const createQuestionnaire = async (documents: Document[]) => {
      const fieldGroups = mergeFieldGroups(documents);

      const autoSections: Section[] = [];

      Object.values(fieldGroups).forEach((fieldGroup, index) => {
        const section: Section = {
          title: fieldGroup.name,
          description: '',
          questions_attributes: [],
          display_order: index,
          show_logic: null,
          logic_enabled: false,
        };

        autoSections.push(section);
      });

      let questionnaire = await updateClioQuestionnaire.mutateAsync({
        questionnaireId,
        clioQuestionnaire: {
          id: parseInt(questionnaireId),
          sections_attributes: autoSections,
          target_count: documents.length,
          analytics: [
            {
              name: 'Completed Questionnaire Auto Generation',
              data: {},
            },
          ],
        },
      });

      const mappedSections: {
        id: number;
        questions_attributes: PartialQuestion[];
      }[] = [];

      questionnaire.sections_attributes.forEach((section) => {
        const fieldGroup = Object.values(fieldGroups).find(
          (g) => g.name === section.title,
        );

        if (!fieldGroup) {
          setHasAutoGenerateErrors(true);
          return;
        }

        const questions: PartialQuestion[] = [];

        Object.values(fieldGroup.fields).forEach((field) => {
          const combinedNormalizedField = `${fieldGroup.normalized_field_group}.${field.normalized_field}`;

          const question: PartialQuestion = {
            title: field.name,
            description: field.hint ?? '',
            question_type: field.type,
            question_options_attributes: [],
            question_mappings_attributes: [
              {
                questionnaire_id: parseInt(questionnaireId),
                external_field_identifier: combinedNormalizedField,
              },
            ],
            is_required: false,
          };

          if (
            question.question_type === 'QuestionMultipleChoiceSingle' ||
            question.question_type === 'QuestionMultipleChoiceMulti'
          ) {
            const questionOptions: QuestionOption[] = [];

            field.multiple_choice_options.forEach((option) => {
              const questionOption: QuestionOption = {
                label: option.label,
                value: option.value,
                question_option_type: 'QuestionOptionStatic',
                question_option_mappings_attributes: [
                  {
                    external_field_identifier: String(option.value),
                  },
                ],
              };

              questionOptions.push(questionOption);
            });

            question.question_options_attributes = questionOptions;
          }

          questions.push(question);
        });

        if (autoGeneratePredefinedQuestionnaireQuestions) {
          fieldGroup.predefined_questions.forEach((predefinedQuestion) => {
            const question: PartialQuestion = {
              title: predefinedQuestion.question,
              description: predefinedQuestion.hint ?? '',
              question_type: predefinedQuestion.question_type,
              question_options_attributes: [],
              question_mappings_attributes: [],
              is_required: false,
            };

            if (
              question.question_type === 'QuestionMultipleChoiceSingle' ||
              question.question_type === 'QuestionMultipleChoiceMulti' ||
              question.question_type === 'QuestionCheckbox'
            ) {
              const questionOptions: QuestionOption[] = [];

              predefinedQuestion.options.forEach((option) => {
                const questionOption: QuestionOption = {
                  label: option.option,
                  value: option.option,
                  question_option_type: 'QuestionOptionStatic',
                  question_option_mappings_attributes: [],
                };

                questionOptions.push(questionOption);
              });

              question.question_options_attributes = questionOptions;
            }

            questions.push(question);
          });
        }

        if (!section.id || questions.length === 0) {
          setHasAutoGenerateErrors(true);
          return;
        }

        const mappedSection = {
          id: section.id,
          questions_attributes: questions,
        };

        mappedSections.push(mappedSection);
      });

      questionnaire = await updateClioQuestionnaire.mutateAsync({
        questionnaireId,
        clioQuestionnaire: {
          id: parseInt(questionnaireId),
          // @ts-ignore: we can partially update the questionnaire.
          sections_attributes: mappedSections,
          analytics: [
            {
              name: 'Questionnaire Initial Data Fill',
              data: {},
            },
          ],
        },
      });

      await reuseUntaggedFieldMappingsFromPreviousQuestionnaire(questionnaire);

      setIsSuccess(true);
    };

    if (!isQuestionnaireReady || !isDocsReady) return;

    if (
      documents &&
      documents.length > 0 &&
      questionnaire &&
      questionnaire.sections_attributes.length === 0
    ) {
      createQuestionnaire(documents);
    }

    if (
      (documents && documents.length === 0) ||
      (questionnaire && questionnaire.sections_attributes.length > 0)
    ) {
      setIsSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isQuestionnaireReady, isDocsReady]);

  useEffect(() => {
    if (!isTemplatesReady || !questionnaireTemplates) return;

    getDocumentFields(
      {
        templateIds:
          questionnaireTemplates.map((template) =>
            String(template.template_id),
          ) ?? [],
      },
      {
        onSuccess: () => {
          setIsDocsReady(true);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTemplatesReady]);

  if (isDocumentMappingError || isTemplatesError || isQuestionnaireError) {
    snackbar.enqueueSnackbar(
      'Error, Failed to load Questionnaire Builder. Please try again later.',
      {
        variant: 'error',
      },
    );
    history.push(`/questionnaires/`);

    return <></>;
  }

  if (isSuccess) {
    // wait 1 second to allow the questionnaire to be saved before redirecting, if we don't wait, mapping doesn't appear in the builder.
    setTimeout(() => {
      history.push(
        `/questionnaires/${questionnaireId}/builder?isAutoGenerated=true&hasAutoGenerateErrors=${hasAutoGenerateErrors}`,
      );
    }, 1000);
  }

  return (
    <LoadingOverlay
      title="Generating questions from your documents..."
      relative={false}
    />
  );
};
