import { useMutation } from 'react-query';
import { APIError } from '~/src/utils/error';
import { getPreviouslyMappedQuestionsURL } from '../urls';
import {
  getClioQuestionnaireAuthToken,
  questionnaireAuthorizationHeaders,
} from '../utils';

export const useGetPreviouslyMappedQuestions = (orgFprint: string) => {
  return useMutation<any, APIError, any>({
    mutationFn: async (payload) => {
      const token = await getClioQuestionnaireAuthToken(orgFprint);
      const response = await fetch(getPreviouslyMappedQuestionsURL(), {
        method: 'POST',
        headers: {
          ...questionnaireAuthorizationHeaders(token),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      return response.json();
    },
  });
};
