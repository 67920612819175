import {
  Subscription,
  SubscriptionV2,
  BillingCycleType,
  SubscriptionV2Plans,
  Invoice,
  FormattedInvoiceItem,
  BundleProduct,
} from './types';
import {
  WORD_DOCUMENT_AUTOMATION_MONTHLY_PRICE,
  WORD_DOCUMENT_AUTOMATION_YEARLY_PRICE,
  CONTENT_PACKAGE,
  COURT_FORMS_MONTHLY_PRICE,
  COURT_FORMS_YEARLY_PRICE,
  COURT_FORMS,
  SEATS_MONTHLY_PRICE,
  SEATS_YEARLY_PRICE,
  SEAT_PRODUCT_ID,
  NYSBA_SURROGATE_ID,
  WORD_PRODUCT_ID,
  SEATS_PRODUCT_PREFIX,
  FEDERAL_FORMS_PRODUCT_SUFFIX,
  COURT_FORMS_PRODUCT_SUFFIX,
  DOC_AUTO_PRODUCT_SUFFIX,
  QUESTIONNAIRES_PREFIX,
  QUESTIONNAIRES_PRODUCTS,
} from './constants';

import {
  COURT_FORM_ANNUAL_PLANS,
  COURT_FORM_MONTHLY_PLANS,
  DOCUMENT_AUTOMATION_PLANS,
  SEATS,
} from '~/src/utils/constants';

import { keyBy } from 'lodash';
import type { Plan } from '~/src/components/Modal/ConfigurePlan';
import { ProductPricing } from './hooks/useGetProductPricing';

/*
In stripe, we name our products by convention like {PRODUCT_ID}_MONTHLY or {PRODUCT_ID}_YEARLY
In this application, we maintain the PRODUCT_ID constants and can use this function to get 
the conventionalized product name in stripe
*/

export const getRawProductID = (plan: string) =>
  plan.replace(/_(MONTHLY|YEARLY)/, '');

export const getProductID = (billingCycle: BillingCycleType, plan: string) =>
  `${plan}_${billingCycle === 'annual' ? 'YEARLY' : 'MONTHLY'}`;

export const billingCycleToDisplayString = (billingCycle: BillingCycleType) =>
  billingCycle === 'annual' ? 'year' : 'month';

export const getCourtFormLabelFromPlanId = (plan_id: string) =>
  COURT_FORMS.find(({ id }) => plan_id.startsWith(id))?.name || 'Court Form';

export const getPlanPrice = (price: number, billingCycle: BillingCycleType) =>
  `$${price}/${billingCycle === 'annual' ? 'year' : 'month'}`;

export const getWordDocumentAutomationProductPrice = (
  billingCycle: BillingCycleType,
) =>
  billingCycle === 'annual'
    ? WORD_DOCUMENT_AUTOMATION_YEARLY_PRICE
    : WORD_DOCUMENT_AUTOMATION_MONTHLY_PRICE;

export const getNYSBASurrogatePrice = () => CONTENT_PACKAGE[0]!.price;

export const getCourtFormProductPrice = (billingCycle: BillingCycleType) =>
  billingCycle === 'annual'
    ? COURT_FORMS_YEARLY_PRICE
    : COURT_FORMS_MONTHLY_PRICE;

export const getSeatsProductPrice = (billingCycle: BillingCycleType) =>
  billingCycle === 'annual' ? SEATS_YEARLY_PRICE : SEATS_MONTHLY_PRICE;

export const getSeatsPlanFromSubscription = (subscription: SubscriptionV2) =>
  subscription.plan.find(({ fields }) =>
    fields.plan_id.startsWith(SEAT_PRODUCT_ID),
  )!;

export const getSeatsFromSubscription = (subscription: SubscriptionV2) =>
  subscription.quantity;

export const getBillingCycleFromSubscription = (subscription: SubscriptionV2) =>
  getSeatsPlanFromSubscription(subscription).fields.interval === 'month'
    ? 'monthly'
    : 'annual';

export const getNysbaPlanFromSubscription = (subscription: SubscriptionV2) =>
  subscription.plan.find(({ fields }) =>
    fields.plan_id.startsWith(NYSBA_SURROGATE_ID),
  );

export const getWordPlanFromSubscription = (subscription: SubscriptionV2) =>
  subscription.plan.find(({ fields }) =>
    fields.plan_id.startsWith(WORD_PRODUCT_ID),
  );

export const getCourtFormPlansFromSubscription = (
  subscription: SubscriptionV2,
) =>
  COURT_FORMS.filter(
    ({ id }) =>
      !!subscription.plan.find(({ fields }) => fields.plan_id.startsWith(id)),
  ).map(({ id }) => id);

export const isSubscriptionCanceled = ({
  canceled_at,
  status,
}: Subscription | SubscriptionV2) => !!canceled_at || status === 'canceled';

export const getPlanDifference = (
  existing: SubscriptionV2Plans,
  next: SubscriptionV2Plans,
) => {
  if (next.seats !== existing.seats) {
    return {
      add_plans: [],
      remove_plans: [],
      seat: next.seats,
      duration: next.billingCycle,
      interval_change: false,
    };
  }

  const interval_change = next.billingCycle !== existing.billingCycle;

  if (interval_change) {
    return {
      add_plans: [],
      remove_plans: [],
      seat: next.seats,
      duration: next.billingCycle,
      interval_change,
    };
  }

  const add_plans = [];
  const remove_plans = [];

  if (existing.contentPackage !== next.contentPackage) {
    if (next.contentPackage) {
      add_plans.push(getProductID(next.billingCycle, NYSBA_SURROGATE_ID));
    } else {
      remove_plans.push(
        getProductID(existing.billingCycle, NYSBA_SURROGATE_ID),
      );
    }
  }

  if (existing.word !== next.word) {
    if (next.word) {
      add_plans.push(getProductID(next.billingCycle, WORD_PRODUCT_ID));
    } else {
      remove_plans.push(getProductID(existing.billingCycle, WORD_PRODUCT_ID));
    }
  }

  if (existing.courtForms !== next.courtForms) {
    next.courtForms.forEach((id) => {
      if (existing.courtForms.includes(id)) return;
      add_plans.push(getProductID(next.billingCycle, id));
    });

    existing.courtForms.forEach((id) => {
      if (next.courtForms.includes(id)) return;
      remove_plans.push(getProductID(existing.billingCycle, id));
    });
  }

  return {
    add_plans,
    remove_plans,
    seat: next.seats,
    duration: next.billingCycle,
  };
};

export const convertToBillingCycleType = (billingCycle?: string) => {
  let cycle: BillingCycleType;
  if (billingCycle === 'monthly' || billingCycle === 'mo') {
    cycle = 'monthly';
  } else {
    cycle = 'annual';
  }
  return cycle;
};

interface Products {
  seatsProduct: undefined | FormattedInvoiceItem;
  docAutoProduct: undefined | FormattedInvoiceItem;
  courtFormsProducts: { [plan_id: string]: FormattedInvoiceItem };
  bundleProduct: undefined | BundleProduct;
}

const applyQuestionnairePricingChanges = (
  products: Products,
  invoice: Invoice | undefined,
  productPricing: ProductPricing,
) => {
  const questionnairesProduct = invoice?.items.find((item) =>
    item.plan_id.includes(QUESTIONNAIRES_PREFIX),
  );
  const numCourtForms = Object.keys(products.courtFormsProducts).length;
  const firstCourtForm = Object.values(products.courtFormsProducts)?.[0];
  const hasDocAuto = !!products.docAutoProduct;

  if (
    !questionnairesProduct ||
    questionnairesProduct.plan_id ===
      QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_EMBEDDED_MONTHLY ||
    questionnairesProduct.plan_id ===
      QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_EMBEDDED_YEARLY ||
    (!hasDocAuto && numCourtForms === 0)
  ) {
    return;
  }

  // only court forms
  if (!hasDocAuto) {
    firstCourtForm!.subtotal += questionnairesProduct.subtotal;
    firstCourtForm!.productLabel = `PDF court forms automation (${
      firstCourtForm!.productLabel
    })`;
    return;
  }

  // only doc auto
  if (numCourtForms === 0) {
    products.docAutoProduct!.subtotal += questionnairesProduct.subtotal;
    return;
  }

  const unbundledCourtForms = Object.values(products.courtFormsProducts).slice(
    1,
    numCourtForms,
  );

  // used to show the user how much money they saved by bundling
  const questionnairePackageWithoutBundling: keyof typeof productPricing =
    questionnairesProduct.plan_id ===
    QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_BUNDLE_MONTHLY
      ? QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_STANDALONE_MONTHLY
      : QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_STANDALONE_YEARLY;
  const priceDifferenceBundle =
    (productPricing[questionnairePackageWithoutBundling] ?? 0) -
    questionnairesProduct.subtotal;

  products.bundleProduct = {
    courtFormProduct: firstCourtForm!.productLabel,
    docAutoProduct: products.docAutoProduct!.productLabel,
    productLabel: 'Bundle',
    billingCycle: firstCourtForm!.billingCycle,
    price:
      firstCourtForm!.subtotal +
      products.docAutoProduct!.subtotal +
      questionnairesProduct?.subtotal,
    priceDifferenceBundle,
    hint: `PDF court forms automation (${firstCourtForm?.productLabel}) + MS Word automation`,
  };

  products.docAutoProduct = undefined;
  products.courtFormsProducts = {};
  unbundledCourtForms.forEach((courtFormProduct) => {
    products.courtFormsProducts[courtFormProduct.plan_id] = courtFormProduct;
  });
};

export const getFormattedProductsFromInvoice = (
  invoice: Invoice | undefined,
  productPricing: ProductPricing,
) => {
  const products: Products = {
    seatsProduct: undefined,
    docAutoProduct: undefined,
    courtFormsProducts: {},
    bundleProduct: undefined,
  };

  const courtFormsMap = keyBy(
    [...COURT_FORM_ANNUAL_PLANS, ...COURT_FORM_MONTHLY_PLANS],
    'id',
  );
  const docAutoMap = keyBy(
    [...DOCUMENT_AUTOMATION_PLANS.monthly, ...DOCUMENT_AUTOMATION_PLANS.annual],
    'id',
  );

  const seatMap = keyBy(SEATS, 'id');

  const productMap = { ...courtFormsMap, ...docAutoMap, ...seatMap };

  invoice?.items.forEach((item) => {
    const formattedItem: FormattedInvoiceItem = {
      ...item,
      billingCycle: convertToBillingCycleType(
        productMap[item.plan_id]?.interval,
      ),
      productLabel: productMap[item.plan_id]?.name || '',
    };

    if (
      formattedItem.plan_id.includes(COURT_FORMS_PRODUCT_SUFFIX) ||
      formattedItem.plan_id.includes(FEDERAL_FORMS_PRODUCT_SUFFIX)
    ) {
      products.courtFormsProducts[formattedItem.plan_id] = formattedItem;
    } else if (formattedItem.plan_id.includes(SEATS_PRODUCT_PREFIX)) {
      products.seatsProduct = formattedItem;
    } else if (formattedItem.plan_id.includes(DOC_AUTO_PRODUCT_SUFFIX)) {
      products.docAutoProduct = formattedItem;
    }
  });

  applyQuestionnairePricingChanges(products, invoice, productPricing);
  return products;
};

export const getQualifyingQuestionnairePlan = ({
  billingFrequency,
  isCourtFormsSelected,
  isDocAutoSelected,
}: {
  billingFrequency: string;
  isCourtFormsSelected: boolean;
  isDocAutoSelected: boolean;
}) => {
  let questionnairesProduct;
  if (isCourtFormsSelected && isDocAutoSelected) {
    if (billingFrequency === 'mo') {
      questionnairesProduct =
        QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_BUNDLE_MONTHLY;
    } else {
      questionnairesProduct =
        QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_BUNDLE_YEARLY;
    }
  } else {
    if (billingFrequency === 'mo') {
      questionnairesProduct =
        QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_STANDALONE_MONTHLY;
    } else {
      questionnairesProduct =
        QUESTIONNAIRES_PRODUCTS.QUESTIONNAIRES_STANDALONE_YEARLY;
    }
  }
  return questionnairesProduct;
};

export const modifyPlanPickerModalEntries = ({
  plansToRender,
  latestSelectedOptions,
  isCourtFormsSelected,
  isDocAutoSelected,
  isEligibleForQuestionnairesEmbedded,
  modalType,
  productPricingData,
  isQuestionnairesGaEnabled,
}: {
  plansToRender: Plan[];
  latestSelectedOptions: boolean[];
  isCourtFormsSelected: boolean;
  isDocAutoSelected: boolean;
  isEligibleForQuestionnairesEmbedded: boolean;
  modalType: 'da' | 'cf';
  productPricingData: ProductPricing;
  isQuestionnairesGaEnabled: boolean;
}) => {
  if (
    isEligibleForQuestionnairesEmbedded ||
    !isQuestionnairesGaEnabled ||
    !plansToRender[0]
  ) {
    return;
  }
  const questionnairesProduct = getQualifyingQuestionnairePlan({
    billingFrequency: plansToRender[0]?.interval ?? '',
    isCourtFormsSelected,
    isDocAutoSelected,
  });

  if (modalType === 'da' && !isCourtFormsSelected) {
    plansToRender[0].price =
      plansToRender[0].price +
      (productPricingData[questionnairesProduct] ?? 0) / 100;
    return;
  }

  if (modalType === 'cf' && !isDocAutoSelected) {
    const numSelectedCourtForms = latestSelectedOptions.filter(
      (option) => option,
    ).length;

    if (numSelectedCourtForms === 0) {
      plansToRender.forEach((plan) => {
        plan.price =
          plan.price + (productPricingData[questionnairesProduct] ?? 0) / 100;
      });
    } else {
      const firstSelectedPlanIndex = latestSelectedOptions.findIndex(
        (plan) => plan,
      );
      plansToRender[firstSelectedPlanIndex]!.price +=
        (productPricingData[questionnairesProduct] ?? 0) / 100;
    }
  }
};
