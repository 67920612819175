import { stringify } from 'qs';
import env from '~/src/utils/env';
import { UpcomingInvoiceParams } from './types';

const getBaseUrl = (orgFprint: string) =>
  `${env.apiUrl}/subscriptions/api/v1/org/${orgFprint}`;

export const getCancelSubscriptionReasonUrl = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/cancel_reasons/`;

export const getCustomerUrl = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/stripe_customers/`;

export const getSubscriptionUrl = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/subscriptions/`;

export const getRenewSubscriptionUrl = (orgFprint: string) =>
  `${getSubscriptionUrl(orgFprint)}renew/`;

export const getSubscriptionV2Url = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/v2/subscriptions/`;

export const getUpcomingInvoiceURL = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/v1/upcoming_invoices/`;

export const getUpcomingInvoiceV2URL = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/v2/upcoming_invoices/`;

export const getUpcomingInvoiceURLWithParams = (
  orgFprint: string,
  params: Partial<UpcomingInvoiceParams> = {},
) =>
  `${getUpcomingInvoiceURL(orgFprint)}?${stringify(params, {
    arrayFormat: 'repeat',
  })}`;

export const getUpcomingInvoiceV2URLWithParams = (
  orgFprint: string,
  params: Partial<UpcomingInvoiceParams> = {},
) =>
  `${getUpcomingInvoiceV2URL(orgFprint)}?${stringify(params, {
    arrayFormat: 'repeat',
  })}`;

export const getProductPricing = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/pricing/`;

export const getQuestionnaireEmbeddedEligibility = (orgFprint: string) =>
  `${getBaseUrl(orgFprint)}/subscriptions/questionnaire_embedded_eligibility`;
