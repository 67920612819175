export const STATE_FORM_ACCESSES = [
  'MI_JC_FORMS',
  'AL_JC_FORMS',
  'CA_JC_FORMS',
  'GA_JC_FORMS',
  'VA_JC_FORMS',
  'NE_JC_FORMS',
  'IL_JC_FORMS',
  'ME_JC_FORMS',
  'MO_JC_FORMS',
  'SC_JC_FORMS',
  'CT_JC_FORMS',
  'AK_JC_FORMS',
  'WY_JC_FORMS',
  'NC_JC_FORMS',
  'ND_JC_FORMS',
  'MA_JC_FORMS',
  'MD_JC_FORMS',
  'TN_JC_FORMS',
  'VT_JC_FORMS',
  'KS_JC_FORMS',
  'OH_JC_FORMS',
  'WV_JC_FORMS',
  'IA_JC_FORMS',
  'MS_JC_FORMS',
  'CO_JC_FORMS',
  'UT_JC_FORMS',
  'NM_JC_FORMS',
  'DE_JC_FORMS',
  'RI_JC_FORMS',
  'KY_JC_FORMS',
  'WA_JC_FORMS',
  'NV_JC_FORMS',
  'NH_JC_FORMS',
  'ID_JC_FORMS',
  'TX_JC_FORMS',
  'AZ_JC_FORMS',
  'OR_JC_FORMS',
  'LA_JC_FORMS',
  'IN_JC_FORMS',
  'AR_JC_FORMS',
  'SD_JC_FORMS',
  'NY_JC_FORMS',
  'WI_JC_FORMS',
  'FL_JC_FORMS',
  'HI_JC_FORMS',
  'NJ_JC_FORMS',
  'CA_BC_JC_FORMS',
  'OK_JC_FORMS',
  'DC_JC_FORMS',
  'MN_JC_FORMS',
  'PA_JC_FORMS',
  'MT_JC_FORMS',
] as const;

type StateFormsAccesses = typeof STATE_FORM_ACCESSES[number];

const FEATURE_ACCESSES = [
  'MANAGE_LEAD',
  'SINGLE_MATTER_IMPORT',
  'TEMPLATE_DEFAULT_VALUES',
  'PRO_EXPERIENCE',
  'FEDERAL_FORMS',
  'CLIO_SYNC_ETAG_SKIP',
  'CLIO_SYNC_UPDATED_SINCE_SKIP',
  'QUESTIONNAIRES',
  'QUESTIONNAIRES_GA',
  'ESIGN',
  'INTAKE_FORMS',
  'TAGS_V2',
  'SUBSCRIPTIONS_V2',
  'V4_STACK_JSON_ENABLED',
  'PDF_CALCULATIONS',
  'WORD_DOCUMENT_AUTOMATION',
  'CLIO_FREE',
  'ADDIN_LONG_DOCUMENTS',
  'DRAFT_SIGNAL_SUPPORT',
  'MAINTENANCE_BANNER',
  'CBS_INTEGRATION',
] as const;

type FeatureAccessesBase = typeof FEATURE_ACCESSES[number];

export type FeatureAccessType = FeatureAccessesBase | StateFormsAccesses;

export type FeatureAccessValue = {
  feature_name: FeatureAccessType;
  access_type: 'DISABLED' | 'UNLIMITED';
  quantity: number;
  access_granted_on: string;
  expires_on?: string;
  can_use_feature: boolean;
};

export type FeatureAccesses = Record<FeatureAccessType, FeatureAccessValue>;

export const ALL_FEATURE_ACCESSES = [
  ...FEATURE_ACCESSES,
  ...STATE_FORM_ACCESSES,
];

export const ALL_COURT_FORMS_ACCESSES: FeatureAccessType[] = [
  ...STATE_FORM_ACCESSES,
  'FEDERAL_FORMS',
];
